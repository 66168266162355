export function getAssetUrl(path: string) {
  if (isDataUrl(path)) {
    return path;
  }

  const fixedPath = path.startsWith('/') ? path.slice(1) : path;
  return process.env.ASSET_PATH + fixedPath;
}

function isDataUrl(path: string) {
  return path.startsWith('data:');
}
