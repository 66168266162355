/**
 * The input mode for the user, this determines how a cell is interacted with.
 */
export enum NumberInputMode {
  /** Tapping the number multiple times cycles through values and notes */
  Cycle = 'cycle',
  /** There is a dedicated toggle that determines if a user inputs values or notes */
  Toggle = 'toggle',
}

export enum NumberInputModeConfig {
  /** Tapping the number multiple times cycles through values and notes */
  Cycle = 'cycle',
  /** There is a dedicated toggle that determines if a user inputs values or notes */
  Toggle = 'toggle',
  /** defaults to cycle but user can switch */
  PreferCycle = 'prefer-cycle',
  /** defaults to toggle but user can switch */
  PreferToggle = 'prefer-toggle',
}
